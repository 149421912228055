import React from "react";
import TextField from "@material-ui/core/TextField";
import Popover from "@material-ui/core/Popover";
import ReactGPicker from "react-gcolor-picker";
import { InputLabel, Box, withStyles } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";

import { ClassyWidget } from "reactform/classywidget";

const useStyles = (theme) => ({
    colorPaper: {
        marginRight: 5,
    },
    colorBox: {
        borderRadius: 5,
    },
});

class GradientPickerWidget extends ClassyWidget {
    constructor(props) {
        super(props);
        this.state = {
            color: this.render_value(),
            anchorEl: null,
        };
    }
    static defaultProps = {
        ...ClassyWidget.defaultProps,
    };
    render() {
        const { classes, show_label, type = "text" } = this.props;
        const field = this.get_field();
        const base_field = field.field;

        const handleClick = (event) => {
            this.setState({ anchorEl: event.currentTarget });
        };

        const handleClose = () => {
            this.setState({ anchorEl: null });
        };

        const open = Boolean(this.state.anchorEl);
        const id = open ? `simple-popover-${base_field.label_id}` : undefined;

        return (
            <React.Fragment>
                <InputLabel htmlFor={base_field.label_id} shrink={true}>
                    {show_label && field.label}
                </InputLabel>
                <Box display="flex" flexDirection="row" alignItems="center">
                    <Paper elevation={2} className={classes.colorPaper}>
                        <Box
                            width={22}
                            height={22}
                            display="flex"
                            className={classes.colorBox}
                            style={{
                                background: this.state.color || "#ffffff",
                            }}
                        />
                    </Paper>
                    <TextField
                        id={base_field.label_id}
                        value={this.state.color}
                        onClick={handleClick}
                        multiline
                        fullWidth
                        maxRows={1}
                    ></TextField>
                </Box>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={this.state.anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                    }}
                >
                    <ReactGPicker
                        value={this.state.color}
                        format={"hex"}
                        solid={false}
                        gradient={true}
                        showGradientMode={false}
                        defaultColors={[]}
                        onChange={(color) => {
                            this.setState({ color: color });
                            this.handleChange(color);
                        }}
                        showAlpha={false}
                    />
                </Popover>
            </React.Fragment>
        );
    }
}

export default withStyles(useStyles)(GradientPickerWidget);
