/* React Base Storage class */
import $ from 'fakequery';
import { error_report } from "debugfail";
import {
    is_simple_type,
    Signal,
    ObjectSignals
} from "signals";
import stores from "storeregistry";
import { current_user } from "storeregistry";
import Periodic from "periodic";
import { POST_SIGNAL, PERMISSION_FAILURE, USER_RESOLVED } from "storages/ajaxobservables";
import LocalStorageBacking from "storages/localstoragebacking";
import FieldStorageBacking from "storages/fieldstoragebacking";
import AjaxStorageBacking from "storages/ajaxstoragebacking";
import { DialogContentText } from '@material-ui/core';

const MAX_GETTING_COUNT = 3;

function url_for_key(key) {
    /* resolve a storage key to a final url */
    var url_mapping = document.getElementById('url-mapping');
    if (!url_mapping) {
        if (stores[key] && stores[key].url) {
            // Test cases where we are piggy-backing on a form definition...
            return stores[key].url;
        }
        console.warn(`No #url_mapping element in document, include django tag storagemap in base template, looking for ${key}`);
    } else {
        var prop_name = 'data-url-' + key;
        var final_url = url_mapping.getAttribute(prop_name);
        if (!final_url) {
            console.warn(`Storage key: ${key} is not in #url-mapping as ${prop_name}`);
        } else {
            // console.log(`Key: ${key} => ${final_url}`);
            return final_url;
        }
    }
    return null;
}
var current_backing = null;
const current_ajax_backing = () => {
    if (current_backing === null) {
        current_backing = new AjaxStorageBacking({ debug: false });
    }
    return current_backing;
};

var BaseStorage = function (state) {
    var storage = $.extend({
        'key': '',
        'url': '',
        'period': null,
        'periodic': null,
        'debug': false,
        'last_success': false,
        'refresh_on_post': true,
        'convert': null, // (newstate,storage) => produce newstate with server => client data-type conversions
        'merge': null, // (newstate,storage) => update storage with new state from server
        'change': Signal(),
        'error_signal': Signal(),
        'user': null,
        'ts': null,
        'default_context': null,
        'extra_context': null,
        'default_options': null,
        'objects': {},
        'object_signals': ObjectSignals({
            name: 'storage-signals'
        }),
        'last_modified': {},
        'ignore_property_changes': {
            'timestamp': true,
            'detection_details': true
        },
        'loading': Signal(),
        '__identity_compare_only__': true,
        'ensure_keys': (update) => {
            /* default implementation of ensure_keys just populates object storage */
            if (update) {
                storage.object_updated(update);
            }
        },
        'no_listener_count': 0,
        'getting_count': 0,
    }, state || {});
    $.extend(storage, {
        get_last_value: function () {
            return storage[storage.type_key];
        },
        object_updated: function (obj) {
            if (obj === null || obj === undefined) {
                return;
            }
            if (obj.key) {
                storage.object_signals.update(obj);
            }
            for (var prop in obj) {
                if (is_simple_type(obj[prop])) {
                    continue;
                }
                if (obj[prop].key) {
                    storage.object_updated(obj[prop]);
                } else if (Array.isArray(obj[prop])) {
                    $.map(obj[prop], storage.object_updated);
                }
            }
        },
        get_backing: function () {
            if (!storage.backing) {
                if (storage.key && (!storage.url || !storage.url.length)) {
                    /* use global storage registry to get our final url */
                    storage.url = url_for_key(storage.key);
                }
                if (storage.url) {
                    storage.backing = current_ajax_backing();
                } else if (storage.field) {
                    storage.backing = FieldStorageBacking({ field: storage.field });
                } else {
                    console.log(`No backing for storage ${storage.key || storage.url}`);
                }
            }
            return storage.backing;
        },
        poll: function () {
            /* continuously poll the back-end */
            if (storage.period) {
                if (storage.debug) {
                    console.log("Poll on " + storage.url);
                }
                const has_run = storage.ensure_periodic();
                if (storage.periodic && !has_run) {
                    // Now force it to run right now...
                    storage.periodic.trigger();
                }
            } else {
                console.log(`No period specified on ${storage.key || storage.url}, not starting polling`);
            }
        },
        ensure_periodic: function () {
            /* Ensure correct periodic handler has run, return whether it has just been run */
            if (storage.period) {
                if (!storage.periodic) {
                    if (storage.debug) {
                        console.log(`Create periodic timer for ${storage.key || storage.url}`);
                    }
                    storage.periodic = Periodic({
                        'period': storage.period,
                        'refresh_on_post': storage.refresh_on_post
                    });
                    if (storage.periodic.refresh_on_post) {
                        POST_SIGNAL.listen(storage.periodic.trigger);
                    }
                    storage.periodic.signal.listen(
                        storage.get_periodic.bind(storage)
                    );
                }
                if (storage.debug) {
                    console.log(`Ensuring periodic calls on ${storage.key || storage.url}`);
                    storage.periodic.debug = true;
                }
                return storage.periodic.ensure();
            } else {
                if (storage.debug) {
                    console.log(`No period on ${storage.key || storage.url}`);
                }
            }
            return false;
        },
        get_periodic: function () {
            /* periodic get that filters for nothing-listening */
            if (storage.debug) {
                console.log("Periodic get " + storage.key || storage.url);
            }
            if (storage.getting && storage.getting_count > MAX_GETTING_COUNT) {
                console.warning(
                    "Previous " +
                        storage.getting_count +
                        " requests for " +
                        (storage.key || storage.url) +
                        " still in progress, skipping current call"
                );
                return;
            }
            if (storage.last_success && storage.periodic && !storage.change.listener_count()) {
                storage.no_listener_count = storage.no_listener_count + 1;
                if (storage.no_listener_count > 10) {
                    storage.deregister();
                }
                if (storage.debug) {
                    console.log(`Nothing listening to ${storage.key || storage.url} at the moment, skipping ${storage.no_listener_count}`);
                }
                return;
            }
            storage.no_listener_count = 0;
            return storage.get(
                storage.url,
            );
        },
        get_default_options: function (options) {
            return {
                ...storage.default_options,
                ...options,
            };
        },
        get_default_context: function (context) {
            if (context) {
                return context;
            }
            if (storage.default_context === undefined || storage.default_context === null) {
                return {};
            }
            const maybe_callable = storage.default_context;
            if (maybe_callable instanceof Function) {
                return maybe_callable();
            } else {
                return maybe_callable;
            }
        },
        update_extra_context: function (extra) {
            storage.extra_context = {...storage.extra_context, ...extra}
        },
        remove_extra_context: function (key) {
            if(key in storage.extra_context){
                delete storage.extra_context[key]
            }
            else{
                console.log("Key not found in extra context object")
            }
        },
        remove_all_extra_context: function () {
            storage.extra_context = null;
        },
        get: function (url, context, options = null) {
            var backing = storage.get_backing();
            if (!backing) {
                throw `Could not find storage backing for ${storage.key}`;
            }
            url = url || storage.url;
            // context = { ...storage.get_default_context(context) };
            context = { ...storage.get_default_context(context), ...storage.extra_context };
            options = options || storage.get_default_options();
            let headers = {};
            if (storage.last_modified[url]) {
                if (storage.debug) {
                    console.log(`Adding If-Modified-Since ${storage.last_modified[url]} ${url}`);
                }
                headers['If-Modified-Since'] = storage.last_modified[url];
                context.partial = 'on';
                context.ts = storage.ts;
            } else if (storage.last_success && storage.ts) {
                if (storage.debug) {
                    console.log(`Adding ts to context for ${url} ${(new Date(storage.ts * 1000))}`);
                }
                context.partial = 'on';
                context.ts = storage.ts;
            }
            if (storage.debug) {
                console.log("Get on " + url);
            }
            if (storage.periodic) {
                storage.periodic.stop();
            }

            const start = new Date();
            storage.getting = true;
            storage.getting_count = storage.getting_count + 1;
            storage.loading.send(storage);
            return Promise.resolve(backing.get(url, context, options, headers).then((data) => {
                const stop = new Date();
                storage.getting = false;
                storage.getting_count = storage.getting_count - 1;
                if (storage.debug) {
                    console.debug(`Response on ${url} after ${stop - start}ms`);
                }
                if (data && (data.success || !(data.error))) {
                    storage.last_error = null;
                    if (data && data.headers && data.headers['last-modified']) {
                        storage.last_modified[url] = data.headers['last-modified'];
                    }
                    if (storage.debug) {
                        console.log(`Success result on ${url} after ${stop - start}ms`);
                    }
                    try {
                        storage.last_success = true;
                        if (data.ts) {
                            storage.ts = data.ts;
                        }
                        if (data.unmodified) {
                            if (storage.debug) {
                                console.log(`Unmodified ${url} not sending updates`);
                            }
                            storage.loading.send(storage);
                            if (storage.periodic) {
                                storage.periodic.backoff(0);
                                storage.periodic.start();
                            }
                            return;
                        }
                        storage.set(data);
                        if (storage.user) {
                            console.log(`Found user in storage ${storage.key}: ${storage.user.username}`);
                            USER_RESOLVED.send(storage.current_user());
                        }
                    } catch (e) {
                        error_report(
                            e,
                            "Unable to complete storage.set for " + JSON.stringify(data)
                        );
                        const error_message = {
                            'error': true,
                            'messages': [
                                'Unable to interpret the results from the server',
                            ],
                        };
                        storage.error_signal.send(error_message);
                        storage.last_error = error_message;
                        throw e;
                    }
                } else if (data && data.error) {
                    if (data.messages) {
                        error_report(null, `Error loading: ${data.messages.join(' ')}`);
                    } else {
                        error_report(null, 'Error loading data ' + data.error);
                    }
                    storage.last_success = false;
                    if (data.auth_failure) {
                        console.info(`Auth failure on ${url}`);
                        PERMISSION_FAILURE.send(storage);
                    }
                    const error_message = {
                        'error': true,
                        'messages': data.messages || [data.auth_failure ? 'Permission denied' : 'Failure loading data'],
                    };
                    storage.error_signal.send(error_message);
                    storage.last_error = error_message;
                } else if (data === null || data === undefined) {
                    /* 304 event, but unfortunately also e.g. a cancelled/timeout request */
                    storage.last_success = true;
                    if (storage.debug) {
                        console.debug("Null response, content unchanged");
                    }
                } else {
                    error_report(null, 'No success or error field in data: ' + data);
                    storage.last_success = false;
                }
                if (storage.periodic) {
                    storage.periodic.backoff(0);
                    storage.periodic.start();
                }
                storage.loading.send(storage);
                return data;
            }).catch((e) => {
                const stop = new Date();
                console.error(`Failure on ${url} after ${stop - start}ms`);
                // error_report(e, "Unable to load data");
                storage.last_error = {
                    'error': true,
                    'messages': [
                        `${e}`,
                    ],
                };
                storage.last_success = false;
                storage.getting = false;
                storage.getting_count = storage.getting_count - 1;
                storage.loading.send(storage);
                if (storage.periodic) {
                    storage.periodic.backoff();
                    storage.periodic.start();
                }
                throw e;
            }));
        },
        post: function (parameters, url, options = null) {
            /* ask our backing to perform a post */
            return storage.get_backing().post(parameters, url, options);
        },
        update: function () {
            storage.change.send(storage);
        },
        base_merge: (state, storage) => {
            $.extend(storage, state);
            return storage;
        },
        set: function (state) {
            if (storage.convert) {
                state = storage.convert(state, storage);
            }
            if (state.partial && storage.merge) {
                storage.merge(state, storage);
            } else {
                storage.base_merge(state, storage);
            }
            // TODO: restart periodic on set?
            storage.ensure_keys(state);
            storage.update();
        },
        current_user: function () {
            var user_record = {};
            if (storage.user) {
                $.extend(user_record, storage.user);
            } else {
                $.extend(user_record, {
                    'permissions': [],
                    'groups': [],
                    'is_superuser': false,
                    'is_staff': false,
                    'is_anonymous': true,
                    'name': ''
                });
            }
            $.extend(user_record, {
                has_permission: function (key) {
                    if (user_record.is_superuser) {
                        return true;
                    }
                    return (user_record.permissions.indexOf(key) !== -1);
                }
            });
            return user_record;
        },
        deregister: function () {
            if (storage.debug) {
                console.log(`Removing storage ${storage.key || storage.url} as stale`);
            }
            if (storage.periodic) {
                storage.periodic.stop();
            }
            if (storage.change && storage.change.listener_count() == 0 && stores[storage.key] === storage) {
                delete stores[storage.key];
            }
        }
    });
    if (storage.key && storage.key.length && !(stores[storage.key])) {
        stores[storage.key] = storage;
    }
    if (storage.debug) {
        console.log(`Setting up storage for ${storage.url} in debug mode`);
    }
    if (!storage.key && !storage.url) {
        throw (`Expect a key or url on every storage ${JSON.stringify(state)}`);
    }
    return storage;
};

var FormStorage = function (state) {
    if (state.key && state.key.length && (stores[state.key])) {
        return stores[state.key];
    }
    var storage = BaseStorage({
        'url': '',
        'key': state.key || '',
        'template_cache': {
        },
        'debug': false,
        refresh_on_post: true,
    });
    $.extend(storage, state || {});
    $.extend(storage, {
        final_url: function (key, id) {
            return storage.url + '/' + key + '/' + (id ? id : '');
        },
        get_form_template: function (key, context = null) {
            /* get a form template, cache it or just return if cached */
            const cache_key = `${key}-${JSON.stringify(context)}`;
            if (storage.template_cache[cache_key] !== undefined) {
                var current = storage.template_cache[cache_key];
                if (current && current.then) {
                    if (storage.debug) {
                        console.log("Already retrieving");
                    }
                    return current;
                } else {
                    if (storage.debug) {
                        console.log("Already in cache " + cache_key);
                    }
                    return Promise.resolve(
                        {
                            success: true,
                            form: JSON.parse(JSON.stringify(storage.template_cache[cache_key]))
                        }
                    );
                }
            } else {
                if (storage.debug) {
                    console.log("Not in cache, pulling " + JSON.stringify(storage.template_cache));
                }
                storage.template_cache[cache_key] = storage.get_form(key, null, context).then(function (success) {
                    if (success.form) {
                        if (storage.debug) {
                            console.log("Storing form cache for " + key);
                        }
                        storage.template_cache[cache_key] = success.form;
                        var form = {};
                        $.extend(form, success.form);
                        return { 'success': true, form: form };
                    } else {
                        console.log("No form in response " + JSON.stringify(success));
                        delete storage.template_cache[cache_key];
                    }
                    return success;
                }, function (failure) {
                    console.log("Failure retrieving the form description");
                    throw failure;
                });
                return storage.template_cache[cache_key];
            }
        },
        get_form: function (key, id, params, options = {}) {
            var backing = storage.get_backing();
            if (backing) {
                var defer = ''
                if (options.use_post) {
                    defer = backing.post({ ...params, get_form_structure_only: true }, storage.final_url(key, id), { no_signal: true });
                }
                else defer = backing.get(storage.final_url(key, id), params);
                return defer;
            } else {
                console.log(`No backing for storage ${storage.key}, cannot load ${key}`);
                return Promise.resolve({
                    'error': true,
                    'messages': [
                        `No backing (url) defined for storage ${storage.key}`,
                    ]
                });
            }
        },
        validate_form: function (key, id, params, options = null) {
            params.validate_only = true;
            return storage.get_backing().post(params, storage.final_url(key, id), options);
        },
        save_form: function (key, id, params, options = null) {
            storage.getting = true;
            storage.loading.send(storage);
            return storage.get_backing().post(params, storage.final_url(key, id), options).then((result) => {
                storage.getting = false;
                storage.loading.send(storage);
                return result;
            });
        },
        ensure_keys: function () {
        }
    });
    return storage;
};


export {
    FormStorage,
    BaseStorage,
    current_user,
    POST_SIGNAL,
    AjaxStorageBacking,
    LocalStorageBacking,
    FieldStorageBacking,
    stores,
    url_for_key,
};
