/* Cliff bridge debugger */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useStorage from 'storages/usestorage';
import {BaseStorage} from 'storages/basestorage';
import { Typography } from '@material-ui/core';
import ATXTable from 'atxtable/atxtable';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';

const ROOT_URL = '/cliffbridge/1/';

const CliffBridgeSummaryStorage = BaseStorage({
    url:`${ROOT_URL}`,
    form_key: 'status',
    status: null,
    period: 15,
    debug: false,
});

const CliffBridgeStorage = BaseStorage({
    url:`${ROOT_URL}info`,
    form_key: 'status',
    status: null,
    period: 15,
    debug: false,
});
const useStyles = makeStyles(theme => ({
    good: {
        color: theme.palette.success.main,
    },
    bad: {
        color: theme.palette.error.main,
    },
    actionHolder: {
        display: 'flex',
        width: '100%',
        flexDirection: 'row',
        '&>*': {
            'flex': 1,
        }
    }
}));

const FactoryCliffSummary = (props) => {
    /* Summary view with overall summary data and much lighter interactions with the server */
    const classes = useStyles();
    const [cliffStatus,setCliffStatus] = React.useState();
    const onStorageUpdate = React.useCallback((storage) => {
        setCliffStatus(storage.status);
    },[CliffBridgeSummaryStorage]);
    useStorage(CliffBridgeSummaryStorage, onStorageUpdate);
    if (!cliffStatus) {
        return 'Loading Summary';
    }
    const data = [
        cliffStatus,
        // TODO: allow for multiple...
    ];

    return <div>
        <ATXTable 
            data={data}
            class={{
                holder:classes.rootTable,
            }}
            columns={[
                {
                    'accessor': 'runtime',
                    'Header': 'Run-Time',
                    'Cell': (props) => {
                        return props.row?.original?.runtime_display || '';
                    }
                },
                {
                    'accessor': 'memory_percent',
                    'Header': 'Memory %',
                },
                {
                    'accessor': 'cpu_percent',
                    'Header': 'CPU %',
                },
                {
                    'accessor': 'task_count',
                    'Header': 'Tasks',
                    'Cell': (props) => {
                        const task_count = props.value;
                        const clients = props.row?.original?.client_count || 0;
                        if (task_count && clients) {
                            return <span>{task_count} ({Math.round(task_count/clients*100)/100})</span>;
                        } else {
                            return '';
                        }
                    }
                },
            ]}
        />
    </div>

};



const FactoryCliffBridge = (props) => {
    const classes = useStyles();
    const [status,setStatus] = React.useState('summary');
    const handleChange = React.useCallback((event, newValue) => {
        setStatus(newValue);
    });
    return <div><Tabs aria-label="CliffBridge Views" onChange={handleChange} value={status} classes={{
        color: 'primary',
    }}>
        <Tab
            label="Summary"
            key={`summary`}
            value={`summary`}
            classes={{
                root: classes.tightTab,
                selected: classes.tightTabSelected,
            }}
        />
        <Tab
            label="Sessions"
            key={`sessions`}
            value={`sessions`}
            classes={{
                root: classes.tightTab,
                selected: classes.tightTabSelected,
            }}

        />
        <Tab
            label="Tasks"
            key={`tasks`}
            value={`tasks`}
            classes={{
                root: classes.tightTab,
                selected: classes.tightTabSelected,
            }}

        />
    </Tabs>
    {status === 'summary' && <FactoryCliffSummary />}
    {status === 'sessions' && <FactoryCliffDetails />}
    {status === 'tasks' && <FactoryCliffTasks />}
    </div>;

};
const FactoryCliffTasks = (props) => {
    const classes = useStyles();
    const [cliffStatus,setCliffStatus] = React.useState();
    const onStorageUpdate = React.useCallback((storage) => {
        setCliffStatus(storage.status);
    },[CliffBridgeStorage]);
    useStorage(CliffBridgeStorage, onStorageUpdate);

    if (!cliffStatus) {
        return 'Loading data';

    }
    return <React.Fragment>
        <Typography>Cliff Bridge Tasks {(cliffStatus && cliffStatus.task_count)|| 'Loading'}</Typography>
        <ATXTable 
            data={(cliffStatus && cliffStatus.tasks)||[]}
            classes={{
                holder: classes.rootTable,
            }}
            filterable={true}
            columns={[
                {
                    'accessor':'1',
                    'Header':'Name',
                },
                {
                    'accessor':'0',
                    'Header':'Code',
                },
            ]}
    /></React.Fragment>;

};

const FactoryCliffDetails = (props) => {
    const classes = useStyles();
    const [cliffStatus,setCliffStatus] = React.useState();
    const onStorageUpdate = React.useCallback((storage) => {
        setCliffStatus(storage.status);
    },[CliffBridgeStorage]);
    useStorage(CliffBridgeStorage, onStorageUpdate);

    if (!cliffStatus) {
        return 'Loading data';
    }

    return <div>
        <ATXTable 
            data={(cliffStatus && cliffStatus.clients)||[]}
            classes={{
                holder: classes.rootTable,
            }}
            filterable={true}
            columns={[
                {
                    'accessor':'unique_key',
                    'Header':'Unique Key',
                    'Cell': (props) => {
                        const {node} = props.row.original;
                        if (node && node.node_id) {
                            return <a href={`/m/nodes/${node.node_id}`}>{props.value}</a>;
                        } else {
                            return `${props.value}`;
                        }
                    },
                },
                {
                    'accessor':'visilink',
                    'Header':'Visilink',
                },
                {
                    'accessor':'connection_id',
                    'Header':'Connection',
                },
                {
                    'accessor':'node.node_name',
                    'Header': "Name",
                },
                {
                    'accessor':'metadata.generation',
                    'Header': "Generation",
                },
                {
                    'accessor':'metadata.product_md.serial_number_dish',
                    'Header': "DISH Serial Number",
                },
                {
                    'accessor':'metadata.ip_addresses',
                    'Header': "IP Addresses",
                    'Cell': (props) => {
                        const ips = props.value;
                        if (!ips) {
                            return '';
                        }
                        return ips.join(", ");
                    }
                },
                {
                    'accessor':'metadata.mac_addresses',
                    'Header': "MAC Addresses",
                    'Cell': (props) => {
                        const macs = props.value;
                        if (!macs) {
                            return '';
                        }
                        return macs.join(", ");
                    }
                },
                {
                    'accessor': 'metadata.firmware_version',
                    'Header': 'Firmware',
                },

            ]}
        />
    </div>;

};

export default FactoryCliffBridge;