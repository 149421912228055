import { BaseStorage } from 'storages/basestorage';
import { stores, FormStorage } from './basestorage';
import { FormContext } from 'formprovider';
import { url_for_key } from './basestorage';
import PropTypes from 'prop-types';
import React from 'react';
import { LoadingDisplay } from 'reactloading';
import { shallow_compare } from 'signals';


const TabularStorage = (props) => {
    props = { ...props };
    if (!props.url) {
        props = tabular_storage_props(props);
    }
    if (props.key) {
        if (stores[props.key]) {
            console.log(`Existing tabular storage key: ${props.key}`);
            return stores[props.key];
        }
    }
    if (!(props.form_key && props.form_key.length)) {
        console.error(`Should have a form-key for form-view tabular storage ${JSON.stringify(props)}`);
    }
    if (!props.forms_key) {
        console.error(`Should have a forms-key for form-view tabular storage ${JSON.stringify(props)}`);
    }
    /* BaseStorage registers with stores if we provide a key */
    const base = BaseStorage(props);
    return base;
};

class WithTabularStorage extends React.Component {
    static defaultProps = {
        'storage_props': null,
        'Component': null,
    };
    state = {
        'storage': null,
        'storage_props': {},
        'forms': null,
        'error': false,
        'messages': null,
        'last_props': null,
        'loaded': false,
    };
    onUpdate = (storage) => {
        console.debug(`Update on table for ${this.props.storage_props.forms_key}/${this.props.storage_props.form_key}`);
        const { prop_function } = this.state.storage_props;
        const final_props = ((prop_function && prop_function({}, storage))) || null;
        if ((!final_props) || !shallow_compare(this.state.last_props, final_props, { 'storage': true })) {
            console.debug(`Change to tabular data ${this.state.storage && this.state.storage.url}`);
        }
        this.setState({
            'error': false,
            'messages': null,
            'loaded': true,
            // last_props: final_props,
        });
        // }
    };
    onError = (err) => {
        this.setState({
            'error': true,
            'messages': err.message ? [err.message] : err.messages,
        });
    };
    componentDidMount() {
        const { storage_props } = this.props;
        // console.log(`WithTabularStorage for ${JSON.stringify(storage_props)}`);
        const final_props = tabular_storage_props(storage_props);

        const forms = stores[final_props.forms_key] || FormStorage({
            key: final_props.forms_key
        });
        stores[final_props.forms_key] = forms;

        const storage = TabularStorage(final_props);
        storage.change.listen(this.onUpdate);
        storage.error_signal.listen(this.onError);
        storage.poll();

        this.setState({
            'storage': storage,
            'forms': forms,
            'storage_props': final_props,
        });
        console.debug(`Final storage props ${JSON.stringify(final_props)}`);
    }
    componentWillUnmount() {
        if (this.state.storage) {
            this.state.storage.change.ignore(this.onUpdate);
            this.state.storage.error_signal.ignore(this.onError);
            this.state.storage.periodic && this.state.storage.periodic.stop();
            if (this.props.debug) {
                console.info("Shut down tabular storage");
            }
        }
    }
    render() {
        const { Component, ...restProps } = this.props;
        if ((!this.state.storage) || (!this.state.loaded)) {
            /* Initial empty render before we have the storage */
            return null;
        }
        const child_props = {
            ...restProps,
            storage: this.state.storage,
        };
        const { prop_function } = this.state.storage_props;
        const final_props = (prop_function && prop_function(child_props, this.state.storage)) || child_props;
        return <FormContext.Provider value={{...this.state.forms, ...this.state.storage}}>
            <LoadingDisplay signal={this.state.storage.loading}>
                <Component {...final_props} />
            </LoadingDisplay>
        </FormContext.Provider>;
    }

}

function tabular_storage_props(props) {
    /* Get storage properties for an *unshared* tabular storage */
    const forms_key = props.forms_key;
    const base_url = url_for_key(props.forms_key);
    if ((!props.url) && (!base_url)) {
        console.error(
            `Tabular storage properties have no URL and form_key=${props.form_key} not in the registry ${JSON.stringify(props)}`
        );
    }
    const url = props.url || `${base_url}/${props.form_key}/${props.id || ''}`; // Note: form_key, not props.key
    if (props.debug === true) {
        console.log(`Tabular storage in debug mode: ${forms_key}/${props.form_key}`);
    }
    const passed_form_details = props.form_details;
    const final_props = {
        key: null, // Do not do a global registration
        url: url,
        forms_key: forms_key,
        debug: props.debug === undefined ? false : props.debug,

        period: props.period || 10,

        prop_function: (props, storage) => {
            if (storage) {
                return {
                    quick_settings: storage.quick_settings, // Note: GUI can override what's available
                    ...props,
                    storage,
                    data: storage[final_props.form_key],
                    form_details: storage.form_details || passed_form_details,
                };
            } else {
                return props;
            }
        },
        ...props,
    };
    if (final_props.editing_forms) {
        final_props.default_context = {
            ...(final_props.default_context || {}),
            editing_forms: JSON.stringify(final_props.editing_forms),
        };
    }
    return final_props;
}

function requiring_tabular_storage(Component, storage_props) {
    function with_storage(props) {
        return <WithTabularStorage Component={Component} storage_props={tabular_storage_props(storage_props)} {...props} />;
    }
    return with_storage;
}

const StorageProps = PropTypes.shape({
    key: PropTypes.string, // default based on forms_key and form_key
    forms_key: PropTypes.string.isRequired, // What form-bridge api provides the form
    form_key: PropTypes.string.isRequired, // TabularView registration on the form-bridge api
    id: PropTypes.number, // If specified, the id to pass into the form's url
    form_keys: PropTypes.arrayOf( // Set of editing form-keys that provide column definitions
        PropTypes.string,
    ),
    extra_key: PropTypes.string, // suffix appended to tabular_storage_key
    prop_function: PropTypes.func, // (props,storage) => child_props

    url: PropTypes.string,
    debug: PropTypes.bool,
    period: PropTypes.number,
});
tabular_storage_props.propTypes = {
    props: StorageProps,
};
requiring_tabular_storage.propTypes = {
    Component: PropTypes.object,
    props: StorageProps,
};
WithTabularStorage.propTypes = {
    Component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    storage_props: StorageProps.isRequired,
};

export default TabularStorage;
export { WithTabularStorage, TabularStorage, requiring_tabular_storage, tabular_storage_props };
